// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'

// import translations
import { German } from 'flatpickr/dist/l10n/de.js'
import { Austria } from 'flatpickr/dist/l10n/at.js'
import { English } from 'flatpickr/dist/l10n/default.js'

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {

    initialize() {
        // Get locale from data attribute or default to German
        const locale = this.element.dataset.locale || 'de'

        // Set locale based on country setting
        let selectedLocale
        switch(locale) {
            case 'at':
                selectedLocale = Austria
                break
            case 'en':
                selectedLocale = English
                break
            default:
                selectedLocale = German
        }

        // sets your language
        this.config = {
            locale: selectedLocale,
            time_24hr: true,
            allowInput: true,
            altInput: true,
            altFormat: 'd.m.Y',
            dateFormat: 'd.m.Y',
            altInputClass: "tw-form-control input"
        }
    }
}
